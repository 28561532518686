<template>
    <v-dialog persistent fullscreen v-model="show">
        <v-card :loading="loading" :disabled="loading">
            <!-- in ios add pt-12 instead of pt-4 -->
            <v-card-text class="d-flex align-center justify-space-between" :class="$ios ? 'pt-12' : 'pt-4'">
                <div class="dialog-title">{{ $tr("Add Task") }}</div>
                <v-btn text icon :disabled="loading" @click="show = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text>
                <v-container fluid>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-combobox
                                    @change="update_regions"
                                    v-model="form.city_name"
                                    :rules="city_name_rules"
                                    :label="$tr('City')"
                                    :items="cities"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="12">
                                <v-combobox
                                    :rules="region_name_rules"
                                    v-model="form.region_name"
                                    :label="$tr('Region')"
                                    :items="regions"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    :label="$tr('Pharmacy')"
                                    :items="pharmacies"
                                    :rules="pharmacy_rules"
                                    :item-text="(item) => item.name"
                                    :item-value="(item) => item.pharmacy_id"
                                    v-model="form.pharmacy_id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-switch :label="$tr('Now')" v-model="form.now"></v-switch>
                            </v-col>
                            <v-col cols="12" v-if="!form.now">
                                <v-dialog
                                    ref="dialog"
                                    v-model="show_time_picker_dialog"
                                    :return-value.sync="form.time"
                                    persistent
                                    fullscreen
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.time"
                                            :label="$tr('Task Time')"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            :rules="time_rules"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker v-if="show_time_picker_dialog" v-model="form.time" full-width>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="show_time_picker_dialog = false">
                                            {{ $tr("Cancel") }}
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(form.time)">
                                            {{ $tr("Submit") }}
                                        </v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    :label="$tr('Visitation Type')"
                                    :items="visitation_types"
                                    :item-text="(vt) => vt.text"
                                    :item-value="(vt) => vt.value"
                                    :rules="visitation_type_rules"
                                    v-model="form.visitation_type"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    :label="$tr('Manufacturer')"
                                    :items="manufacturers"
                                    :item-text="(m) => m.name"
                                    :item-value="(m) => m.manufacturer_id"
                                    :rules="manufacturer_rules"
                                    v-model="form.manufacturer_id"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field :label="$tr('Comments')" v-model="form.comments"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex align-center justify-space-between">
                <v-btn :disabled="loading" :loading="loading" text color="primary" @click="show = false">{{
                    $tr("Cancel")
                }}</v-btn>
                <v-btn :disabled="loading || !valid" :loading="loading" text color="primary" @click="submit">{{
                    $tr("Submit")
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import rules from "@/util/rules/index.js";

export default {
    data() {
        return {
            show: false,
            valid: false,
            loading: false,
            cities: [],
            show_time_picker_dialog: false,
            pharmacies: [],
            manufacturers: [],
            regions: [],
            visitation_types: [
                {
                    text: "بيع",
                    value: "sales",
                },
                {
                    value: "collection",
                    text: "تحصيل",
                },
                {
                    value: "visit",
                    text: "زيارة",
                },
            ],
            form: {
                now: false,
                city_name: "",
                region_name: "",
                visitation_type: "",
                pharmacy_id: "",
                comments: "",
                manufacturer_id: "",
                time: "",
                position_data: {
                    latitude: "",
                    longitude: "",
                    accuracy: "",
                },
            },
        };
    },
    models: [{ data: "show", event: "models:show" }],
    watch: {
        async show(v) {
            this.$emit("models:show", v);
            if (v) {
                await this.refresh();
            }
        },
        "form.time"(v) {
            console.log("Time: ", v);
        },
    },
    methods: {
        async submit() {
            this.loading = true;
            try {
                const position = await this.$get_geolocation();

                this.form.position_data = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    accuracy: position.coords.accuracy,
                };

                await this.$api.post("/sr/tasks/register/one", this.form);

                this.$emit("refresh");
                this.show = false;
            } catch (error) {
                if (error?.response?.data?.error?.msg == "Repeated task") {
                    const notification = {
                        msg: this.$tr("this task is repeated"),
                        color: "error",
                    };
                    this.$store.dispatch("user/notify", notification);
                }
                console.log(error);
            }
            this.loading = false;
        },
        async refresh() {
            this.loading = true;
            try {
                if (this.cities.length == 0) {
                    this.cities.push(
                        ...(
                            await this.$api.post("/address/city/fetch/names", null, {
                                sinceMins: 60,
                            })
                        ).data.result.names
                    );
                }

                if (this.pharmacies.length == 0) {
                    this.pharmacies.push(
                        ...(
                            await this.$api.post("/pharmacy/fetch/all", null, {
                                sinceMins: 60,
                            })
                        ).data.result.pharmacies
                    );
                }

                if (this.manufacturers.length == 0) {
                    this.manufacturers.push(
                        ...(
                            await this.$api.post("sr/fetch/manufacturer", null, {
                                sinceMins: 60,
                            })
                        ).data.result.manufacturers
                    );
                }
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        async update_regions(v) {
            try {
                this.regions.splice(0);
                if (!v) {
                    return;
                }
                const regions = (
                    await this.$api.post("/address/region/fetch/names_by_city", {
                        city_name: v,
                    })
                ).data.result.names;
                this.regions.push(...regions);
            } catch (error) {
                console.log(error);
            }
        },
    },
    computed: {
        city_name_rules() {
            const field = "City Name";
            return [rules.required(field)];
        },
        region_name_rules() {
            const field = "Region Name";
            return [rules.required(field)];
        },
        time_rules() {
            const field = "Time";
            return [rules.required(field)];
        },
        pharmacy_rules() {
            const field = "Pharmacy";
            return [rules.required(field)];
        },
        visitation_type_rules() {
            const field = "Visitation Type";
            return [rules.required(field)];
        },
        manufacturer_rules() {
            const field = "Manufacturer";
            return [rules.required(field)];
        },
    },
};
</script>

<style lang="sass">
.dialog-title
    font-size: 24px
    font-weight: 700
</style>
